import styled from "styled-components";
import Logo from "../assets/logo-transparent.webp";
import React from "react";
import { Menu } from "./Menu";
import { useNavigate } from "../hooks/navigation";

type Props = {
  hideLogo?: boolean;
};

export function Header({ hideLogo }: Props) {
  const nav = useNavigate();
  const toHome = () => {
    nav("/");
  };
  return (
    <StyledHeader hideLogo={hideLogo}>
      {!hideLogo && (
        <LogoWrapper onClick={toHome}>
          <img src={Logo} alt="logo" width={120} />
        </LogoWrapper>
      )}
      <Menu />
    </StyledHeader>
  );
}

const StyledHeader = styled.header<{ hideLogo?: boolean }>`
  background-color: #343434;
  height: ${(props) => (props.hideLogo ? "130px" : "250px")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  flex-direction: column;
  gap: 20px;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;
