import React, { useCallback, useState } from "react";
import { Layout } from "../components/layout";
import { SEO } from "../components/SEO";
import styled from "styled-components";
import { ForgotPasswordForm } from "./ForgotPassword";
import { ForgotPasswordContainer } from "../components";
import { LoginForm } from "./LoginForm";

export function RetailersPage() {
  return (
    <Layout>
      <SEO title="Retailers" />
      <ContentWrapper>
        <LoginContainer />
      </ContentWrapper>
    </Layout>
  );
}

const PageTitle = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const onForgotPassword = () => {
  // open url in new tab:
  window.open("https://b2b.salesin.com/Account", "_blank");
};

function LoginContainer() {
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

  return (
    <StyledLoginContainer>
      {forgotPasswordMode ? <ForgotPasswordForm /> : <LoginForm />}
      <ForgotPasswordContainer onClick={onForgotPassword}>
        {forgotPasswordMode ? "Back to login" : "Forgot password?"}
      </ForgotPasswordContainer>
    </StyledLoginContainer>
  );
}

const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 20px;
  min-width: 300px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
