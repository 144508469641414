import React from "react";
import { Layout } from "../components/layout";
import { SEO } from "../components/SEO";
import styled from "styled-components";

const DOWNLOADS = [
  {
    title: "Installation and Maintenance Guidelines and Warranty",
    file: "https://dukes-group-images.web.app/files/Installation%20and%20Maintenance%20Guidelines%20and%20Warranty.pdf",
    icon: "pdf",
  },
];

export function DownloadsPage() {
  return (
    <Layout>
      <SEO title="Downloads" />
      <DownloadsWrapper>
        {DOWNLOADS.map((d) => (
          <DownloadTile>
            <TileHeader>
              {d.title}{" "}

            </TileHeader>
            {/* <TileDescription>
            This is a brief description of download item 1.
          </TileDescription> */}
            <TileLink href={d.file} download target="_blank">
              Download
            </TileLink>
          </DownloadTile>
        ))}
      </DownloadsWrapper>
    </Layout>
  );
}

const DownloadsWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const PageTitle = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const DownloadTile = styled.div`
  background-color: #343434;
  //   border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  width: 280px;
  min-height: 80px;
`;

const TileHeader = styled.h2`
  margin-top: 0;
  font-size: 1.4em;
  line-height: 24px;
`;

const TileDescription = styled.p`
  margin: 10px 0;
`;

const TileLink = styled.a`
  color: #c69c6c;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
