import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { CONFIG } from "../config";
import { logEvent } from "../analytics/init";

export function FloatingContactButton() {
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const [isOpen, setIsOpen] = React.useState(false);

  if (!isMobile) {
    return null;
  }

  return (
    <>
      <FloatingButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen((prev) => !prev);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.88 19.88 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6A19.88 19.88 0 0 1 2.1 4.18 2 2 0 0 1 4 2h3a2 2 0 0 1 2 1.72 13.28 13.28 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 10a16 16 0 0 0 6 6l1.33-1.18a2 2 0 0 1 2.11-.45 13.28 13.28 0 0 0 2.81.7 2 2 0 0 1 1.72 2z" />
        </svg>
      </FloatingButton>
      {isOpen && <PopupMenu close={() => setIsOpen(false)} />}
    </>
  );
}

// Menu pops up above the floating button
function PopupMenu({ close }: { close: () => void }) {
  function fireEvent(action: string) {
    logEvent(`quick_contact_${action}`);
  }

  return (
    <Underlay onClick={close}>
      <PopupMenuWrapper>
        <MenuItem href={`tel:${CONFIG.phone}`} onClick={() => fireEvent("tel")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.88 19.88 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6A19.88 19.88 0 0 1 2.1 4.18 2 2 0 0 1 4 2h3a2 2 0 0 1 2 1.72 13.28 13.28 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 10a16 16 0 0 0 6 6l1.33-1.18a2 2 0 0 1 2.11-.45 13.28 13.28 0 0 0 2.81.7 2 2 0 0 1 1.72 2z" />
          </svg>
          {CONFIG.phone_readable}
        </MenuItem>
        <MenuItem href={`sms:${CONFIG.cell}`} onClick={() => fireEvent("sms")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M21 15a2 2 0 0 1-2 2H8l-4 4V5a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2z" />
            <line x1="8" y1="9" x2="16" y2="9" />
            <line x1="8" y1="13" x2="12" y2="13" />
          </svg>
          SMS
        </MenuItem>
        <MenuItem
          href={`mailto:${CONFIG.email}`}
          onClick={() => fireEvent("mailto")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <rect x="2" y="4" width="20" height="16" rx="2" ry="2" />
            <path d="M2 4l10 8 10-8" />
          </svg>
          {CONFIG.email}
        </MenuItem>
      </PopupMenuWrapper>
    </Underlay>
  );
}

const FloatingButton = styled.div`
  width: 50px;
  height: 50px;
  color: #c69c6c;
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #343434;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const PopupMenuWrapper = styled.div`
  position: fixed;
  bottom: 75px; /* Adjust this value based on the button height and desired margin */
  //   width: 80%;
  right: 25px;
  transform: translate(0%, -10%);
  background-color: #343434;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   gap: 50px;
  z-index: 100;

  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1001;
`;

const MenuItem = styled.a`
  color: #c69c6c;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: flex-start;
  width: 80%;
`;

const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
