import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { useNavigate } from "../hooks/navigation";
import { Footer } from "./footer";

type Options = {
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideLogo?: boolean;
  beforeHeader?: React.ReactNode;
  breadCrumbs?: { name: string; href: string }[];
};

export function Layout({
  children,
  hideHeader,
  hideFooter,
  breadCrumbs,
  beforeHeader,
  hideLogo,
}: { children: React.ReactNode } & Options) {
  const nav = useNavigate();

  return (
    <PageWrapper>
      {beforeHeader}
      {hideHeader ? null : <Header hideLogo={hideLogo} />}
      {breadCrumbs && breadCrumbs.length > 1 && (
        <BreadCrumbsWrapper>
          <StyledBreadcrumbs>
            {breadCrumbs.map(({ name, href }) => (
              <StyledBreadcrumbItem
                key={href}
                href={href}
                onClick={() => nav(href)}
              >
                {name}
              </StyledBreadcrumbItem>
            ))}
          </StyledBreadcrumbs>
        </BreadCrumbsWrapper>
      )}

      {children}
      {hideFooter ? null : <Footer />}
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  margin: 0;
  padding: 0;
  background-color: #343434;
  height: 100vh;
  width: 100vw;
`;

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const StyledBreadcrumbItem = styled.a`
  font-size: 0.8rem;
  color: white;
  cursor: pointer;

  &:not(:last-child)::after {
    content: ">";
    margin: 0 10px;
  }
`;

const StyledBreadcrumbs = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const BreadCrumbsWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;