import React from "react";
import { Layout } from "../components/layout";
import { FlyingTrees } from "../components/FlyingTrees";
import { Logo } from "../components/logo";
import styled from "styled-components";
import { Heading } from "../components/Heading";
import { colours } from "../theme/theme";
import { PRODUCT_RANGES } from "../products/store/state";
import { ProductRangeTile } from "../components/ProductRangeTile";
import { useSEO } from "../hooks/seo";

export function LandingPage() {
  useSEO({ title: `Premium Engineered Timber Flooring` });

  return (
    <Layout
      // hideHeader
      hideLogo
      beforeHeader={
        <FlyingTrees height="80vh">
          <LogoContainer>
            <Logo width={250} />
          </LogoContainer>
        </FlyingTrees>
      }
    >
      <ProductSectionWrapper>
        <Heading level="h2">Our Range</Heading>
        <ProductsContainer>
          {PRODUCT_RANGES.map((range) => (
            <ProductRangeTile key={range.name} range={range} />
          ))}
        </ProductsContainer>
      </ProductSectionWrapper>

      <AboutContainer>
        <Heading level="h2">About Dukes Group</Heading>
        <AboutText>
          {`Dukes Group specialises in wholesaling Premium Engineered Timber Flooring throughout the United States. Providing high quality products to our customers in a range of sizes and grades backed up with industry expertise and professionalism. Timeless elegance with beautiful natural grains providing visual perfection. Engineered timber flooring is much more sustainable than traditional solid timber flooring and also provides many other benefits including its high level of stability, cost effectiveness and time saving advantages.`}
        </AboutText>
        <AboutText>
          Our premium engineered timber flooring range, a fusion of timeless
          elegance and cutting-edge engineering. Crafted with meticulous
          attention to detail, our flooring epitomizes sophistication, offering
          both aesthetic appeal and unmatched durability.
        </AboutText>
        <AboutText>
          Each plank is meticulously engineered using advanced techniques,
          combining layers of natural wood with precision craftsmanship. This
          innovating construction enhances stability, providing lasting
          performance.
        </AboutText>
      </AboutContainer>
    </Layout>
  );
}

const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colours.lighterBlack};
  color: white;
  flex-direction: column;
  padding: 20px;
`;

const AboutText = styled.p`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-size: 0.9rem;
  line-height: 1.5em;
  text-align: center;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-height: 100px;
  @media (max-width: 768px) {
    min-height: 0px;
  }
`;

const ProductSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
`;

const ProductsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  gap: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
